.Container {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    transition: 100ms;
    transform-origin: center;

    animation: opening 100ms;
}

@keyframes opening {
    0% {

        transform: scale(1, 0);
    }
    100% {
        transform: scaleY(1); 
    }
}

.Container.closing {
    transform: scaleY(0);
}

.Game {
    position: absolute;

    padding: 3rem 4rem;

    z-index: 20;
    top: 50%;
    left: 50%;

    width: fit-content;
    height: fit-content;

    transform: translate(-50%, -50%);

    background-color: white;

    border: 0.1rem solid black;
    border-radius: 1rem;
    box-shadow: 0.1rem 0.1rem 0 black;

    transition: 500ms;
}

.Game.nonInteractive {
    pointer-events: none;
}

.Game.solved {
    transform: translate(-50%, -50%) rotateY(360deg);
}

.Backdrop {
    position: absolute;

    width: 100%;
    height: 100%;

    z-index: 19;
    top: 0;
    left: 0;

    backdrop-filter: blur(40px) brightness(120%) saturate(80%);
}

.BackButton {
    display: block;

    margin: auto;
    margin-top: 2rem;

    text-align: center;
}

.Grid {
    margin: 0 auto;
    width: max-content;

    border-spacing: 0;
    border-collapse: collapse;

    user-select: none;
}

.Grid tbody tr td { transition: 100ms; }

.RowHints {
    text-align: right;
    padding-right: 0.3rem;
    transform-origin: right;
}
.RowHints.hovered { transform: scaleX(1.05); }

.ColumnHints {
    text-align: center;
    vertical-align: bottom;
    transform-origin: bottom;
}
.ColumnHints.hovered { transform: scaleY(1.05); }

.RowHints.solved, .ColumnHints.solved { color: #999; text-decoration: line-through; }
.RowHints.hovered, .ColumnHints.hovered { font-weight: bolder; }

.HintsRow, .Grid tbody:last-child {
    border-bottom: 0.1rem solid black;
}

.Grid tbody tr td:first-child, .Grid tbody tr td:last-child {
    border-right: 0.1rem solid black;
}

.Cell {
    width: 1.5rem;
    height: 1.5rem;

    border: 1px solid black;
}

.Cell.middleRow { border-bottom-width: 0.1rem; }
.Cell.middleColumn { border-right-width: 0.1rem; }

.Cell.hovered { background-color: #DDD; }
.Cell.adjacent { background-color: #EEE; }

.Cell.marked { background-color: #0077B6; }
.Cell.filled { background-color: black; }
.Cell.invalid {
    text-align: center;
    color: #555;
}

.Mistakes {
    font-size: 1.2rem;
    text-align: center;
    margin: auto;
}

.Mistakes p { margin-bottom: 0.6rem; }

.Mistake {
    display: inline-block;

    margin: 0 0.2rem;

    width: 1rem;
    height: 1rem;

    border: 0.1rem solid black;
    border-radius: 1rem;

    box-shadow: 0.1rem 0.1rem 0 black;

    transition: 200ms;
}

.Mistake.full {
    background-color: #FF5C5C;
}

.Popup {
    position: absolute;

    padding: 2rem 4rem;

    z-index: 25;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    background-color: #DDD;

    border: 0.1rem solid black;
    border-radius: 1rem;

    box-shadow: 0.1rem 0.1rem 0 black;

    animation: popupOpen 100ms;
}

@keyframes popupOpen {
    0% {
        transform: translate(-50%, -50%) scale(1, 0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1, 1);
    }
}

.Popup h1 {
    margin-top: 0;

    text-shadow: 0.1rem 0.1rem 0 #AAA;
    text-align: center;
}

@media only screen and (hover: none) and (pointer: coarse) {
    .Game {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        align-items: center;
        justify-content: center;
    }

    .Mistakes {
        margin: 0;
    }

    .Popup {
        width: max-content;
    }
}