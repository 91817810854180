export default class Picross {
    static Empty = "emptyCell";
    static Marked = "markedCell";
    static Filled = "filledCell";

    static Hints(line) {
        let hints = [];

        let prev = Picross.Empty;
        for (let i = 0; i < line.length; i++) {
            if (line[i] === Picross.Filled) {
                if (prev === Picross.Filled) {
                    hints[hints.length - 1] += 1;
                } else {
                    hints.push(1);
                }
            }
            prev = line[i];
        }

        return hints;
    }

    static LinesEqual(line1, line2) {
        for (let i = 0; i < line1.length; i++) {
            if (line1[i] === Picross.Filled && line2[i] === Picross.Filled) {
                return false;
            }
        }
        return true;
    }

    static Random(size) {
        let picross = new Picross();
        for (let i = 0; i < size ** 2; i++) {
            if (Math.round(Math.random()) === 1) {
                picross.grid[i] = Picross.Filled;
            }
        }
        return picross;
    }

    constructor(size, data) {
        this.size = size;

        this.grid = Array(size ** 2).fill(Picross.Empty);
        if (typeof data === "string") {
            for (let i = 0; i < Math.min(data.length, this.grid.length); i++) {
                if (data.charAt(i) === 'X') {
                    this.grid[i] = Picross.Filled;
                }
            }
        } else if (data !== undefined) {
            for (let i = 0; i < Math.min(data.length, this.grid.length); i++) {
                this.grid[i] = data[i] ? Picross.Filled : Picross.Empty;
            }
        }
    }

    valueAt(x, y) {
        return this.grid[y * this.size + x];
    }

    updatedPicross(value, x, y) {
        let newPicross = Object.assign(Object.create(Object.getPrototypeOf(this)), this)
        newPicross.grid[y * this.size + x] = value;

        return newPicross;
    }

    rowSolved(other, row) {
        for (let i = 0; i < this.size; i++) {
            if (other.rows[row][i] === Picross.Filled && this.rows[row][i] !== Picross.Filled) {
                return false;
            }
        }
        return true;
    }
        
    columnSolved(other, column) {
        for (let i = 0; i < this.size; i++) {
            if (other.columns[column][i] === Picross.Filled && this.columns[column][i] !== Picross.Filled) {
                return false;
            }
        }
        return true;
    }

    solved(other) {
        for (let i = 0; i < this.size; i++) {
            if (!this.rowSolved(other, i)) {
                return false;
            }
        }
        return true;
    }

    get rows() {
        let rows = [];
        for (let i = 0; i < this.size; i++) {
            rows.push(this.grid.slice(i * this.size, (i + 1) * this.size));
        }

        return rows;
    }

    get columns() {
        let columns = [];
        for (let i = 0; i < this.size; i++) {
            columns[i] = [];
        }

        for (let i = 0; i < this.grid.length; i++) {
            let column = i % this.size;
            columns[column].push(this.grid[i]);
        }

        return columns;
    }

    get rowHints() {
        return this.rows.map(row => Picross.Hints(row));
    }

    get columnHints() {
        return this.columns.map(column => Picross.Hints(column));
    }
}