.Container {
    display: block;
    position: relative;

    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    overflow-x: scroll;
    overflow-y: scroll;
}

.Container::-webkit-scrollbar {
    display: none;
}

.Board {
    background-color: black;
}

.Chunk {
    position: absolute;

    margin: 0;
    padding: 0;
}

.Chunk.loading {
    background: #EEE;
}

.Tile {
    position: absolute;

    box-sizing: border-box;
    border: 1px solid black;
    
    background-size: cover;
    background-position: center;

    transition: 200ms;
}

.Tile:not(.solved) { background-color: #0077B6; }

.Tile:not(.locked, .solved) {
    cursor: pointer;
}

.Tile span {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) rotate(-20deg);

    font-size: 1.3rem;
    color: white;
    text-shadow: 0.1rem 0.1rem 0 black;

    transition: 200ms;

    opacity: 0.6;
}

.Tile:not(.locked, .solved):hover { background-color: #3C97DC; }
.Tile:not(.locked, .solved):hover span {
    transform: translate(-50%, -50%) scale(1.1);
    text-shadow: 0.15rem 0.15rem 0 black;
    opacity: 1;
}

.Tile.solved {
    image-rendering: pixelated;
}

.Tile.solved.loading {
    background: #EEE;
}

.Tile.locked { opacity: 0.8; }
.Tile.locked svg {
    width: 100%;
    height: 100%;

    filter: drop-shadow(0.1rem 0.1rem 0 black);
}

@media only screen and (hover: none) and (pointer: coarse) {
    .Tile span {
        font-size: 2.6rem;
        text-shadow: 0.2rem 0.2rem 0 black;
    }

    .Tile:not(.locked, .solved):hover span {
        text-shadow: 0.3rem 0.3rem 0 black;
    }
}