import React from "react";
import { useCookies } from "react-cookie";

import Map from "./map";
import Game from "./game";
import WelcomeDialog from "./welcome";
import Info from "./info";

export default function App() {
    const [cookies, setCookies] = useCookies(["hideWelcome"]);
    const [showWelcome, setShowWelcome] = React.useState(false);
    const removeWelcome = () => {
        setShowWelcome(false);
        setCookies("hideWelcome", true);
    };

    const [game, setGame] = React.useState(null);

    const finishCallback = (x, y, success, updateChunk) => {
        fetch(`/api/tile/unlock/${x}/${y}`)
            .catch(err => console.error(`failed to unlock tile(${x}, ${y}): ${err}`));
        if (success) {
            fetch(`/api/tile/solve/${x}/${y}`)
                .catch(err => `failed to set tile(${x}, ${y}) solved: ${err}`);
        }
        setTimeout(updateChunk, 200);
        setGame(null);
    };

    const spawnGame = (x, y, updateChunk, data) => {
        setGame(<Game x={x} y={y} data={data} updateChunk={updateChunk} finishCallback={finishCallback} />);
    };

    React.useEffect(() => {
        if (!cookies.hideWelcome) {
            setShowWelcome(true);
        }
    }, []);

    return <>
        {showWelcome ? <WelcomeDialog begin={removeWelcome} /> : <></>}
        <Info />
        <Map spawnGame={spawnGame}></Map>
        {game ? game : <></>}
    </>;
}