import React from "react";

import styles from "../public/css/Info.module.css";
import CrosspaintSVG from "../public/crosspaint.svg";

import { Button } from "./input";

export default function Info() {
    const [closed, setClosed] = React.useState(true);

    const handleClick = () => {
        setClosed(!closed);
    };

    return <div className={styles.Info + (closed ? ` ${styles.closed}`: ` ${styles.opened}`)} onClick={closed ? handleClick : null}>
        <span>?</span>
        <Button content="BACK" onClick={handleClick} />
        <div className={styles.Content}>
            <h2><CrosspaintSVG /></h2>
            <p>CROSSPAINT is a multiplayer puzzle game where players work together by solving individual nonograms to solve a larger overall image.</p>
            <h2>Solving a Tile</h2>
            <p>
                When you select a tile by clicking 'Play', you are presented with a nonogram that needs to be solved to reveal the tile's image. A tile may be locked if another player is solving it.
                <br /><br />
                Use the hints presented beside the rows and columns to solve the nonogram. These hints tell you how many consecutive lines of filled squares are in that column/row. For instance, if the hint is '2 4', then there is a line of 2 filled squares followed by a line of 4 filled squares. There will always be a space of at least 1 square between these lines, but the space may be longer than just 1, so you must use logic and the hints in other columns/rows to determine where the lines are.
                <br /><br />
                You can use the marker to block off squares in the nonogram to prevent mistakes if you're sure there's a space there, or to help you visualize your solution to the nonogram.
            </p>
            <p className={styles.Copyright}>
                CROSSPAINT was designed and developed by George Beers.
                <br />
                <b>© CROSSPAINT 2023. All rights reserved.</b>
            </p>
        </div>
    </div>;
}