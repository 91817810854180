.Map {
    position: fixed;

    padding: 0.8rem;
    width: 5rem;
    height: 5rem;

    bottom: 1rem;
    right: 1rem;
    z-index: 10;

    background-color: white;
    border: 0.1rem solid black;

    border-radius: 0.5rem;

    box-shadow: 0.1rem 0.1rem 0 black;

    transition: 200ms;
    transform-origin: bottom right;
}

.Map.inactive {
    cursor: pointer;
}

.Map.active {
    display: flex;
    flex-direction: column;

    padding: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    bottom: 0;
    right: 0;

    border: none;
    border-radius: 0;
}

.MapImage {
    position: relative;

    width: 100%;
    height: 100%;

    outline: 0.1rem solid black;

    background-size: cover;
}

.Map.active .MapImage {
    width: 70vh;
    height: 70vh;

    outline: none;
    border: 0.1rem solid black;
    box-shadow: 0.1rem 0.1rem 0 black;
}

.Viewport {
    position: absolute;

    background-color: black;
    opacity: 0.5;
}

.Map.active .Viewport {
    display: none;
}

.Map.inactive p { display: none; }
.Map.active p {
    display: block;

    padding-top: 0;
    padding-bottom: 1rem;
    
    text-align: center;
}

.Map.active p svg {
    display: inline;

    padding-bottom: 0.35rem;
    margin-right: 0.3rem;
    width: 10rem;

    vertical-align: middle;
}

.Map .BackButton {
    display: none;
}

.Map.active .BackButton {
    display: block;
    position: fixed;

    top: 1rem;
    left: 1rem;
}

@media only screen and (min-width: 800px) {
    .Map.inactive:hover {
        padding: 2rem;

        width: 20rem;
        height: 20rem;

        border-radius: 1rem;
    }
}

@media screen and (orientation: portrait) {
    .Map.active .MapImage {
        width: 70vw;
        height: 70vw;
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    .Map {
        padding: 1.5rem;
        width: 8rem;
        height: 8rem;
    }
}