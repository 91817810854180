button {
    padding: 1rem 2rem;

    font-family: 'Itim';
    font-size: 1.1rem;
    font-weight: bold;

    background-color: #0077B6;
    color: white;

    text-shadow: 0.1rem 0.1rem 0 black;
    box-shadow: 0.1rem 0.1rem 0 black;

    border: 0.1rem solid black;
    border-radius: 0.5rem;

    cursor: pointer;

    transition: 100ms;
}


button:hover {
    background-color: #3C97DC;
}

button:active {
    box-shadow: 0 0 0 black;
    transform: translate(0.1rem, 0.1rem);
}

.Switch {
    margin: auto;
    margin-bottom: 1.5rem;

    text-align: center;

    transition: 200ms;
    transform-origin: bottom;
}

.Switch label {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.8rem;

    margin-top: 0.4rem;
}

.Label {
    font-size: 1.2rem;
}

.Switch input { opacity: 0; width: 0; height: 0; }

.Slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        transition: 200ms;

        border: 0.1rem solid black;
        border-radius: 1.8rem;

        box-shadow: 0.1rem 0.1rem 0 black;

        background-color: #ccc;
}

.Slider:before {
    position: absolute;
    content: "";

    height: 1.2rem;
    width: 1.2rem;
    left: 0.1rem;
    bottom: 0.1rem;
    background-color: white;

    border: 0.1rem solid black;
    border-radius: 50%;

    transition: 200ms;
}

input:checked + .Slider { background-color: #0077B6; }
input:checked + .Slider:before {
    transform: translateX(1.2rem);
}