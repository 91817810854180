import React from "react";
import { useDraggable } from "react-use-draggable-scroll";

import LockedSVG from "../public/locked.svg";
import styles from "../public/css/Board.module.css";

/* --- */

function useInterval(callback, delay) {
    const savedCallback = React.useRef();

    // Remember the latest callback.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

/* --- */

const ChunkSize = 10;
const TileSize = 100; // px

/* --- */

function Tile({ x, y, updater, tileSize, solved, locked, updateChunk, spawnGame }) {
    const [thumbnail, setThumbnail] = React.useState(false);
    const [inline, setInline] = React.useState({
        width: `${tileSize}px`,
        height: `${tileSize}px`,    
        top: `${(y % ChunkSize) * tileSize}px`,
        left: `${(x % ChunkSize) * tileSize}px`,
    });

    const handleClick = () => {
        fetch(`/api/tile/state/${x}/${y}`)
            .then(res => res.json())
            .then(({ solved, locked }) => {
                if (solved || locked) {
                    if (locked) {
                        updateChunk();
                    }
                    return;
                }

                fetch(`/api/tile/lock/${x}/${y}`)
                    .then(() => fetch(`/api/tile/data/${x}/${y}`)
                                    .then(res => res.json())
                                    .then(data => spawnGame(x, y, updateChunk, data))
                                    .catch(err => console.error(`failed to fetch tile(${x}, ${y}) data: ${err}`)))
                    .catch(err => `failed to lock tile(${x}, ${y}): ${err}`);
            })
            .catch(err => console.error(`failed to fetch tile(${x}, ${y}) state: ${err}`));
    };

    React.useEffect(() => {
        fetch(`/api/tile/state/${x}/${y}`)
            .then(res => res.json())
            .then(({ solved }) => {
                if (solved) {
                    fetch(`/api/tile/thumbnail/${x}/${y}`)
                        .then(res => res.blob())
                        .then(blob => {
                            setThumbnail(true);
                            setInline(({
                                ...inline,
                                ...{ backgroundImage: `url(${URL.createObjectURL(blob)})` }
                            }));
                        })
                        .catch(err => console.error(`failed to fetch tile(${x}, ${y}) thumbnail: ${err}`));
                }
            })
            .catch(err => console.error(`failed to fetch tile(${x}, ${y}) state: ${err}`));
    }, [updater]);

    return <div className={styles.Tile + (solved ? ` ${styles.solved}` + (thumbnail ? "" : ` ${styles.loading}`) : "") + (locked ? ` ${styles.locked}` : "")} style={inline} onClick={handleClick}>
        {!locked && !solved ? <span>PLAY</span> : (locked ? <LockedSVG /> : <></>)}
    </div>;
}

function Chunk({ x, y, tileSize, spawnGame }) {
    const [states, setStates] = React.useState(null);
    const [updater, setUpdater] = React.useState(0);

    const updateChunk = () => {
        setUpdater(updater + 1);
    };

    const inline = {
        width: `${ChunkSize * tileSize}px`,
        height: `${ChunkSize * tileSize}px`,
        top: `${y * ChunkSize * tileSize}px`,
        left: `${x * ChunkSize * tileSize}px`,
    };

    const tiles = () => {
        if (!states) {
            return;
        }

        let tileElements = [];
        for (let i = 0; i < ChunkSize; i++) {
            for (let j = 0; j < ChunkSize; j++) {
                const tileX = x * ChunkSize + j;
                const tileY = y * ChunkSize + i;
                tileElements.push(<Tile key={`${j}:${i}`} x={tileX} y={tileY} updater={updater} tileSize={tileSize} solved={states[i][j].solved} locked={states[i][j].locked} updateChunk={updateChunk} spawnGame={spawnGame} />);
            }
        }

        return tileElements;
    };

    React.useEffect(() => {
        fetch(`/api/board/chunk/${x}/${y}`)
            .then(res => res.json())
            .then(states => setStates(states))
            .catch(err => console.error(`failed to fetch chunk(${x}, ${y}) data: ${err}`))
    }, [updater]);

    return <div className={styles.Chunk + (states ? "" : ` ${styles.loading}`)} style={inline}>{tiles()}</div>;
}

export default function Board({ updateViewport, spawnGame }) {
    const [chunks, setChunks] = React.useState(new Set());

    const [board, setBoard] = React.useState({});
    const [tileSize, setTileSize] = React.useState(100);

    const [inline, setInline] = React.useState({});

    const containerRef = React.useRef();
    const { events } = useDraggable(containerRef, { decayRate: 0.8 });

    const updateInline = () => {
        setTileSize(TileSize * window.devicePixelRatio);
        setInline({ width: `${board.width * tileSize}px`, height: `${board.height * tileSize}px` });
    };

    const getVisibleChunks = ({ x, y, width, height }) => {    
        const [x1, y1, x2, y2] = [x, y, x + width, y + height].map(v =>  v / (ChunkSize * tileSize));
        
        let chunks = [];
        for (let i = Math.floor(x1); i < Math.min(Math.ceil(x2), board.width / ChunkSize); i++) {
            for (let j = Math.floor(y1); j < Math.min(Math.ceil(y2), board.height / ChunkSize); j++) {
                chunks.push({ x: i, y: j });
            }
        }
    
        return chunks;
    };

    const updateChunks = () => {
        updateInline();

        const width = board.width * tileSize;
        const height = board.height * tileSize;

        const bounds = {
            x: containerRef.current.scrollLeft,
            y: containerRef.current.scrollTop,
            width: containerRef.current.clientWidth,
            height: containerRef.current.clientHeight
        };

        updateViewport(bounds.x / width, bounds.y / height, bounds.width / width, bounds.height / height);

        setChunks(new Set([...getVisibleChunks(bounds).map(chunk => JSON.stringify(chunk))]));
    };  
    
    React.useEffect(() => {
        fetch("/api/board/dimensions")
            .then(res => res.json())
            .then(dimensions => setBoard(dimensions))
            .catch(err => console.error(`failed to fetch board dimensions: ${err}`));
    }, []);

    React.useEffect(updateChunks, [board]);

    const chunkElements = [...chunks].map(str => JSON.parse(str)).map(({ x, y }) => <Chunk key={`${x}:${y}`} x={x} y={y} tileSize={tileSize} spawnGame={spawnGame} />);

    return <div ref={containerRef} className={styles.Container} {...events} onResize={updateChunks} onScroll={updateChunks}><div className={styles.Board} style={inline}>{chunkElements}</div></div>;
}