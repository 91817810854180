import React from "react";

import styles from "../public/css/Map.module.css";
import CrosspaintSVG from "../public/crosspaint.svg";

import { Button } from "./input";
import Board from "./board";

export default function Map({ spawnGame }) {
    const [image, setImage] = React.useState(null);
    const [done, setDone] = React.useState(0);

    const [viewport, setViewport] = React.useState({
        x: 0,
        y: 0,
        width: 1,
        height: 1
    });

    const [active, setActive] = React.useState(false);

    const updateViewport = (x, y, width, height) => {
        setViewport({ x: x, y: y, width: width, height: height });
    };

    const clickHandler = () => {
        if (!active) {
            setActive(true);
        }
    };

    React.useEffect(() => {
        fetch("/api/board/partial/0077B6FF")
            .then(res => res.blob())
            .then(blob => setImage(URL.createObjectURL(blob)));
        fetch("/api/board/percentage")
            .then(res => res.json())
            .then(val => setDone(val));
    }, []);

    return <>
        <div className={styles.Map + (active ? ` ${styles.active}` : ` ${styles.inactive}`)} onClick={clickHandler}>
            <Button className={styles.BackButton} onClick={() => setActive(false)} content="BACK" />
            <p><CrosspaintSVG /> <b>{Math.floor(done * 100)}%</b> complete.</p>
            {image ? <div className={styles.MapImage} style={{
                backgroundImage: `url(${image})`
            }}>
                <div className={styles.Viewport} style={{
                    top: `${viewport.y * 100}%`,
                    left: `${viewport.x * 100}%`,
                    width: `${viewport.width * 100}%`,
                    height: `${viewport.height * 100}%`
                }}></div>
            </div> : <></>}
        </div>
        <Board updateViewport={updateViewport} spawnGame={spawnGame} />
    </>;
}