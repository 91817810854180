import React from "react";

import { ToggleSwitch, Button } from "./input";
import Picross from "./picross";

import styles from "../public/css/Game.module.css";

const MaxMistakes = 5;

const PenModes = {
    Filler: "filler",
    Marker: "marker"
};

function Cell({ x, y, pen, setPen, currentPicross, setCurrentPicross, finalPicross, updateMistakes }) {
    const handleClick = (event) => {
        const val = currentPicross.valueAt(x, y);

        const mode = event.button === 2 ? (pen.mode === PenModes.Marker ? PenModes.Filler : PenModes.Marker) : pen.mode;

        if (mode === PenModes.Marker) {
            if (val === Picross.Empty) {
                setCurrentPicross(currentPicross.updatedPicross(Picross.Marked, x, y));
            } else if (val === Picross.Marked) {
                setCurrentPicross(currentPicross.updatedPicross(Picross.Empty, x, y));
            }
        } else {
            if (val === Picross.Empty) {
                setCurrentPicross(currentPicross.updatedPicross(Picross.Filled, x, y));
                if (finalPicross.valueAt(x, y) !== Picross.Filled) {
                    updateMistakes();
                }
            }
        }
    };

    const handleMouseMove = () => {
        setPen({ mode: pen.mode, x: x, y: y });
    };

    return <td
        className={styles.Cell + (x === pen.x && y === pen.y ? ` ${styles.hovered}`: (x === pen.x || y === pen.y ? ` ${styles.adjacent}` : ""))
                               + (y === currentPicross.size / 2 - 1 ? ` ${styles.middleRow}` : "")
                               + (x === currentPicross.size / 2 - 1 ? ` ${styles.middleColumn}` : "")
                               + (currentPicross.valueAt(x, y) === Picross.Filled ? (finalPicross.valueAt(x, y) === Picross.Filled ? ` ${styles.filled}` : ` ${styles.invalid}`) : (currentPicross.valueAt(x, y) === Picross.Marked ? ` ${styles.marked}` : ""))}
        onMouseDown={handleClick}
        onContextMenu={(event) => { event.preventDefault(); return false; }}
        onMouseMove={handleMouseMove}
    >
        {currentPicross.valueAt(x, y) === Picross.Filled && finalPicross.valueAt(x, y) !== Picross.Filled ? "X" : ""}
    </td>;
}

function Grid(props) {
    const { currentPicross, finalPicross, pen } = props;

    let rows = [];

    // column hints row
    rows.push(<tr key={-1} className={styles.HintsRow}>
        <td></td>
        {finalPicross.columnHints.map((hints, i) => <td key={i} className={styles.ColumnHints + (i === pen.x ? ` ${styles.hovered}` : "") + (currentPicross.columnSolved(finalPicross, i) ? ` ${styles.solved}`: "")}>
            {hints.map((hint, j) => <span key={j}>{hint}<br /></span>)}
        </td>)}
    </tr>);

    for (let i = 0; i < currentPicross.size; i++) {
        let cells = [];
        cells.push(<td key={-1} className={styles.RowHints + (i === pen.y ? ` ${styles.hovered}` : "") + (currentPicross.rowSolved(finalPicross, i) ? ` ${styles.solved}` : "")}>
            {finalPicross.rowHints[i].join(" ")}
        </td>);
        
        for (let j = 0; j < currentPicross.size; j++) {
            cells.push(<Cell key={j} x={j} y={i} {...props} />);
        }

        rows.push(<tr key={i} className={styles.Row}>{cells}</tr>);
    }
    
    return <table className={styles.Grid}><tbody>{rows}</tbody></table>;
}

function SuccessPopup({ leaveGame }) {
    return <div className={`${styles.Popup} ${styles.success}`}>
        <h1>Complete!</h1>
        <Button content="Back to board?" onClick={leaveGame} />
    </div>;
}

function FailurePopup({ leaveGame }) {
    return <div className={`${styles.Popup} ${styles.failure}`}>
        <h1>GAME OVER</h1>
        <Button content="Back to board?" onClick={leaveGame} />
    </div>;
}

export default function Game({ x, y, data, updateChunk, finishCallback }) {
    const [closing, setClosing] = React.useState(false);

    const finalPicross = new Picross(Math.sqrt(data.length), data);
    const [currentPicross, setCurrentPicross] = React.useState(new Picross(Math.sqrt(data.length)));

    const [pen, setPen] = React.useState({
        mode: PenModes.Filler,
        x: 0,
        y: 0
    });

    const [popup, setPopup] = React.useState(null);

    const [mistakes, setMistakes] = React.useState(0);

    const leaveGameFailed = () => {
        setClosing(true);
        setTimeout(() => finishCallback(x, y, false, updateChunk), 100);
    };

    const leaveGameSuccess = () => {
        setClosing(true);
        setTimeout(() => finishCallback(x, y, true, updateChunk), 100);
    };

    const updateMistakes = () => {
        if (mistakes >= MaxMistakes - 1) {
            setPopup(<FailurePopup leaveGame={leaveGameFailed} />);
        }
        setMistakes(mistakes + 1);
    };

    const handlePen = (event) => {
        if (event.target.checked) {
            setPen({ mode: PenModes.Marker, x: pen.x, y: pen.y });
        } else {
            setPen({ mode: PenModes.Filler, x: pen.x, y: pen.y });
        }
    };

    React.useEffect(() => {
        if (currentPicross.solved(finalPicross)) {
            setPopup(<SuccessPopup leaveGame={leaveGameSuccess} />);
        }
    }, [currentPicross]);

    return <div className={styles.Container + (closing ? ` ${styles.closing}`: "")}>
        <div className={styles.Backdrop}></div>
        <div className={styles.Game + (mistakes === MaxMistakes || currentPicross.solved(finalPicross) ? ` ${styles.nonInteractive}` : "") + (currentPicross.solved(finalPicross) ? ` ${styles.solved}` : "")}>
            <ToggleSwitch content={<>Marker<br /></>} onChange={handlePen} />
            <Grid currentPicross={currentPicross} setCurrentPicross={setCurrentPicross} finalPicross={finalPicross} pen={pen} setPen={setPen} updateMistakes={updateMistakes} />

            <div className={styles.Mistakes}>
                <p>Mistakes</p>
                {Array.from({ length: MaxMistakes}, (_, i) => i).map(i => <div key={i} className={styles.Mistake + (i < mistakes ? ` ${styles.full}` : "")}></div>)}
            </div>

            <Button className={styles.BackButton} content="BACK" onClick={leaveGameFailed} />
        </div>

        {popup ? popup : <></>}
    </div>;
}