.Backdrop {
    position: fixed;

    width: 100%;
    height: 100%;

    z-index: 30;
    top: 0;
    left: 0;

    backdrop-filter: blur(40px) brightness(120%) saturate(80%);

    transform-origin: center;
}

.Backdrop.closing {
    animation: close 100ms;
}

@keyframes close {
    0% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 0);
    }
}

.WelcomeDialog {
    position: absolute;
    padding: 3rem 4rem;

    top: 50%;
    left: 50%;

    width: fit-content;
    height: fit-content;

    transform: translate(-50%, -50%);

    text-align: center;

    background-color: white;

    border: 0.1rem solid black;
    border-radius: 1rem;

    box-shadow: 0.1rem 0.1rem 0 black;
}

.WelcomeDialog p {
    margin: 2rem 0;
    line-height: 1.4rem;
}

.Info {
    display: inline-block;

    margin: 0 0.3rem;

    width: 1.5rem;
    height: 1.5rem;

    text-align: center;

    font-weight: bold;

    border: 0.1rem solid black;
    border-radius: 2rem;

    box-shadow: 0.1rem 0.1rem 0 black;
}

.CookieInfo {
    margin: 2rem 0 0 !important;

    font-size: 0.7rem;
    color: #777;
}

@media only screen and (hover: none) and (pointer: coarse) {
    .WelcomeDialog {
        display: flex;
        flex-direction: column;

        padding: 0 2rem;

        width: 100%;
        height: 100%;

        align-items: center;
        justify-content: center;
    }

    .WelcomeDialog svg {
        width: 60vw;
    }

    .WelcomeDialog p {
        margin: 2rem;
    }

    .CookieInfo {
        margin: 2rem 2rem 0 !important;
    }
}