.Info {
    transition: 100ms;
}

.Info.closed {
    display: block;
    position: fixed;

    z-index: 5;
    top: 1rem;
    left: 1rem;

    width: 1.5rem;
    height: 1.5rem;

    line-height: 1.5rem;
    text-align: center;

    font-weight: bold;

    background-color: white;

    border: 0.1rem solid black;
    border-radius: 50%;

    box-shadow: 0.1rem 0.1rem 0 black;

    cursor: pointer;
}

.Info.closed button, .Info.closed .Content {
    display: none;
}

.Info.opened {
    position: fixed;

    z-index: 15;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: white;

    overflow: auto;
}

.Info.opened span {
    display: none;
}

.Info.opened button {
    display: block;
    position: absolute;

    top: 1rem;
    left: 1rem;
}

.Content {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    margin: 1rem 30%;
    
    width: 40%;
    min-height: 100vh;
    height: auto;

    text-align: justify;
}

.Content svg {
    display: inline;

    width: 10rem;

    vertical-align: middle;
}

.Copyright {
    margin-top: 3rem;
    line-height: 1.5rem;
    
    text-align: center;
}

@media only screen and (min-width: 800px) {
    .Info.closed:hover {
        transform-origin: center;
        transform: scale(1.2);
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    .Info.closed {
        width: 2.5rem;
        height: 2.5rem;

        line-height: 2.5rem;

        font-size: 1.5rem;
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    .Content {
        margin: 4rem 10%;
        padding: 0;
        width: 80%;
    }

    .Info.opened button {        
        position: relative;

        margin: 1rem auto 0;

        text-align: center;
        transform: translateX(-1rem);
    }
}