import styles from "../public/css/Input.module.css";

function ToggleSwitch({ content, ...props }) {
    return <div className={styles.Switch}>
        <span className={styles.Label}>{content}</span>

        <label>
            <input {...props} type="checkbox"></input>
            <span className={styles.Slider}></span>
        </label>
    </div>;
}

function Button({ content, ...props }) {
    return <button {...props}>{content}</button>;
}

export { ToggleSwitch, Button };