import React from "react";

import styles from "../public/css/Welcome.module.css";
import CrosspaintSVG from "../public/crosspaint.svg";

import { Button } from "./input";

export default function WelcomeDialog({ begin }) {
    const [closing, setClosing] = React.useState(false);

    return <div className={styles.Backdrop + (closing ? ` ${styles.closing}` : "")}>
        <div className={styles.WelcomeDialog}>
            <CrosspaintSVG />
            <p>Welcome to CROSSPAINT.<br />Select a tile to play, or click <span className={styles.Info}>?</span> for information on how to play.</p>

            <Button content="Begin!" onClick={() => { setClosing(true); setTimeout(begin, 100); }} />
            <p className={styles.CookieInfo}>CROSSPAINT uses cookies. By playing CROSSPAINT you are consenting to their usage.</p>
        </div>
    </div>;
}